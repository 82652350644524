// Map.js
import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const Map = ({ countries }) => {
  const redIcon = new L.Icon({
    iconUrl: '../images/red-pin.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const greenIcon = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + '../images/green-pin.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const blackIcon = new L.Icon({
    iconUrl: process.env.PUBLIC_URL + '../images/black-pin.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  const mapboxToken = 'pk.eyJ1IjoiZGFic2VzaCIsImEiOiJjbHFxaTZpejMzOXk4MmpwaHVqeG00bDRoIn0.R1XIAwLavNFEQ0LSY9f7wQ';

  const maxBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(90, 180));

  return (
    <MapContainer
      center={[0, 0]}
      zoom={2}
      className="rounded-lg overflow-hidden"
      style={{ height: '600px', width: '80%', margin: '0 auto' }}
      maxBounds={maxBounds}
    >
      {/* Use Mapbox Streets tile layer with English labels */}
      <TileLayer
        url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/{z}/{x}/{y}?access_token=${mapboxToken}`}
        attribution='&copy; <a href="https://www.mapbox.com/map-feedback/">Mapbox</a> contributors'
        minZoom={2}
        maxZoom={18}
      />
      {countries.map((country) => (
        <Marker
          key={country.id}
          position={[country.lat, country.lng]}
          icon={
            country.status === 'to-travel'
              ? redIcon
              : country.status === 'planning-next'
              ? blackIcon
              : greenIcon
          }
        >
          <Popup>
            <div>
              <h2>{country.name}</h2>
            </div>
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Map;
