// BlogPostList.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import API_BASE_URL from '../config';
import Footer from '../components/footer';
import Breadcrumbs from '../components/Breadcrumbs';

const BlogPostList = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/blog`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        const simplifiedData = data.map(({ id, title }) => ({ id, title }));
        setBlogPosts(simplifiedData);
        setError(null);
      } catch (error) {
        console.error(error);
        setBlogPosts([]);
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const breadcrumbs = [
    { label: 'Home', url: '/' },
    { label: 'Blogs', url: '/blogs' },
  ];

  return (
    <div className="flex flex-col min-h-screen">
      <div className="nav">
      <Breadcrumbs paths={breadcrumbs} /> </div>
      <div className="flex-1">
        
        <h2 className="text-4xl font-semibold mb-10 mt-15" id="blogs">
          Blogs
        </h2>
        <div className="p">
          {loading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>
              <p className="text-red-500">Error: {error.message}</p>
            </div>
          ) : (
            <ul className="space-y-2">
              {blogPosts.map((post) => (
                <li key={post.id}>
                  <Link to={`/blogs/${post.id}`} className="blog-post-link">
                    {post.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
          <hr id="hr2" className="mt-10 mb-6" />
          {/* <p className="mt-30">
            Find me <a href="/">here</a>.
          </p> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BlogPostList;
