// Breadcrumbs.js

import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ paths }) => {
  return (
    <p className="text-lg"> {/* Adjust the font size here */}
      {paths.map((path, index) => (
        <React.Fragment key={index}>
          {index > 0 && <span className="text-gray-500 mx-2">&gt;</span>}
          {index === paths.length - 1 ? (
            <span className="text-gray-700">{path.label}</span>
          ) : (
            <Link
              to={path.url}
              className="text-blue-500 hover:underline transition duration-300 ease-in-out"
            >
              {path.label}
            </Link>
          )}
        </React.Fragment>
      ))}
    </p>
  );
};

export default Breadcrumbs;
