// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
// import About from './Pages/About';
// import Work from './Pages/Work';
// import Quotes from './Pages/Quotes';
// import Plan from './Pages/Plans';
import Travel from './Pages/Travel';
import BlogPostList from './Pages/BlogPostList';
import BlogPost from './Pages/BlogPost';

function App() {
  return (
    // <div>Under Maintainence</div>
    <div className="bg-white-100">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/blogs" element={<BlogPostList />} />
          <Route path="/blogs/:id" element={<BlogPost />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
