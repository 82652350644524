import React, { useState } from 'react';
import Footer from '../components/footer';
import { Analytics } from '@vercel/analytics/react';
import arrowDown from './icon2.svg';
import arrowRight from './icon1.svg';

const Home = () => {
  const [isMainQuestsOpen, setIsMainQuestsOpen] = useState(false);
  const [isChapter1Open, setIsChapter1Open] = useState(false);
  const [isChapter2Open, setIsChapter2Open] = useState(false);
  const [isSideQuestsOpen, setIsSideQuestsOpen] = useState(false);
  const [isRetiredQuestsOpen, setIsRetiredQuestsOpen] = useState(false);
  const [isBookShelfOpen, setBookShelfOpen] = useState(false);
  const [isWorldTravelLogOpen, setIsWorldTravelLogOpen] = useState(false);
  const [isLanguagesOpen, setIsLanguagesOpen] = useState(false);
  const [isActivity1Open, SetIsActivity1Open] = useState(false);
  const [isActivity2Open, SetIsActivity2Open] = useState(false);
  const [isActivity3Open, SetIsActivity3Open] = useState(false);

  const toggleSection = (section) => {
    switch (section) {
      case 'mainQuests':
        setIsMainQuestsOpen(!isMainQuestsOpen);
        break;
      case 'chapter1':
        setIsChapter1Open(!isChapter1Open);
        break;
      case 'chapter2':
        setIsChapter2Open(!isChapter2Open);
        break;
      case 'sideQuests':
        setIsSideQuestsOpen(!isSideQuestsOpen);
        break;
      case 'retiredQuests':
        setIsRetiredQuestsOpen(!isRetiredQuestsOpen);
        break;
      case 'Bookshelf':
        setBookShelfOpen(!isBookShelfOpen);
        break;
      case 'worldTravelLog':
        setIsWorldTravelLogOpen(!isWorldTravelLogOpen);
        break;
      case 'languages':
        setIsLanguagesOpen(!isLanguagesOpen);
        break;
      case 'activity1':
        SetIsActivity1Open(!isActivity1Open);
        break;
      case 'activity2':
        SetIsActivity2Open(!isActivity2Open);
        break;
      case 'activity3':
        SetIsActivity3Open(!isActivity3Open);
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex flex-col pager">
           <Analytics />
      <div className="text-base mt-12">
      <h1 className="text-base">Name: Dipesh Goyal</h1>
      <hr className="w-full my-2 border-gray-400" />
      <p className="text-base">Birthplace: India</p>
      <p className="text-base">Birthday: April 24 </p>
      <hr className="w-full my-2 border-gray-400" />
      <p className="text-base">Company: NRI Australia & New Zealand</p>
      <p className="text-base">Role: Technical Ops Analyst</p>
      <hr className="w-full my-2 border-gray-400" />
      <div className="flex items-center">
        <div className="mr-2" onClick={() => toggleSection('mainQuests')} style={{ cursor: 'pointer' }}>
        {isMainQuestsOpen ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
        </div>
        <span onClick={() => toggleSection('mainQuests')} style={{ cursor: 'pointer' }}>⚔️ Main Quests</span>
        </div>
      {isMainQuestsOpen && (
        <>
         <div className="flex items-center mt-3 ml-10">
            <span onClick={() => toggleSection('chapter1')} style={{ cursor: 'pointer' }}>
              {isChapter1Open ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
            </span>
            <span onClick={() => toggleSection('chapter1')} style={{ cursor: 'pointer' }}>&nbsp;Chapter 1 ('18 – '23): Learn</span>
            </div>
          {isChapter1Open && (
              <ol class="circle mt-2 ml-20 space-y-0">
              <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> Graduate high school</li>
              <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> Graduate with a diploma</li>
              <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> Full-time role at Australian Taxation Office</li>
               <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> Graduate with a Bachelors</li>
                <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> Full-time role as an IT Consultant</li>
              <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> Full-time role as an IT Analyst</li>
            </ol>
          )}
             <div className="flex items-center ml-10">
            <span onClick={() => toggleSection('chapter2')} style={{ cursor: 'pointer' }}>
            {isChapter2Open ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
            </span>
            <span onClick={() => toggleSection('chapter2')} style={{ cursor: 'pointer' }}>&nbsp;Chapter 2 ('23 - present): Exploring the unknown</span>
            </div>
          {isChapter2Open && (
              <ul class="circle mt-2 ml-20 space-y-0">
              <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> 1</li>
              <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> 2</li>
              <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> 3</li>

            </ul>
          )}
        </>
      )}
      <hr className="w-full my-2 border-gray-400" />
      <div className="flex items-center">
        <span className="mr-2" onClick={() => toggleSection('sideQuests')} style={{ cursor: 'pointer' }}>
          {isSideQuestsOpen ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
        </span>
        <span onClick={() => toggleSection('sideQuests')} style={{ cursor: 'pointer' }}>🗝️ Side Quests</span>
      </div>
      {isSideQuestsOpen && (
        <>
        <div class="list-disc mt-1 ml-10 flex items-center ">
          <span onClick={() => toggleSection('activity1')} style={{ cursor: 'pointer' }}>
          {isActivity1Open ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
          </span>
          <span onClick={() => toggleSection('activity1')} style={{ cursor: 'pointer' }}> &nbsp; Fitness</span> 
          </div>
          {isActivity1Open && (
          <ul class="circle mt-2 ml-20 space-y-0">
            <li><input
                      type="checkbox"
                      defaultChecked="true"
                      disabled="true"
                    /> Run a 5k</li>
            <li><input
                      type="checkbox"
                      disabled="true"
                    /> 10 consecutive pull-ups </li>
            <li><input
                      type="checkbox"
                      disabled="true"
                    /> 5 consecutive muscle-ups</li>
            <li><input
                      type="checkbox"
                      disabled="true"
                    /> 1.5x bodyweight bench press</li>
              <li><input
                      type="checkbox"
                      disabled="true"
                    /> Body fat percentage of 10% or less</li>
               <li><input
                      type="checkbox"
                      disabled="true"
                    /> Run a marathon</li>
  
          </ul>
          )}
          <div class="list-disc mt-1 ml-10 flex items-center ">
          <span onClick={() => toggleSection('activity2')} style={{ cursor: 'pointer' }}>
          {isActivity2Open ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
          </span>
          <span onClick={() => toggleSection('activity2')} style={{ cursor: 'pointer' }}> &nbsp; Creativity</span> 
          </div>
          {isActivity2Open && (
          <ul class="circle mt-2 ml-20 space-y-0">
            <li><input
                      type="checkbox"
                      disabled="true"
                    /> Write and publish blogs</li>
            <li><input
                      type="checkbox"
                      disabled="true"
                    /> Learn to play 3 songs on the piano</li>
            <li><input
                      type="checkbox"
                      disabled="true"
                    /> Travel Vlogging</li>
          </ul>
)}
          <div class="list-disc mt-1 ml-10 flex items-center ">
          <span onClick={() => toggleSection('activity3')} style={{ cursor: 'pointer' }}>
          {isActivity3Open ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
          </span>
          <span onClick={() => toggleSection('activity3')} style={{ cursor: 'pointer' }}>&nbsp; Adventure Sports</span> 
          </div>
          {isActivity3Open && (
    <>
      <ul className="circle mt-2 ml-20 space-y-0">
        <li>
          <input
            type="checkbox"
            defaultChecked={true}
            disabled={true}
          />{' '}
          Skydiving
        </li>
        <li>
          <input
            type="checkbox"
            defaultChecked={true}
            disabled={true}
          />{' '}
          Scuba Diving
        </li>
        <li>
          <input
            type="checkbox"
            disabled={true}
          />{' '}
          Bungee Jumping
        </li>
        <li>
          <input
            type="checkbox"
            disabled={true}
          />{' '}
          Paragliding
        </li>
        <li>
          <input
            type="checkbox"
            defaultChecked={true}
            disabled={true}
          />{' '}
          Kayaking
        </li>
      </ul>
    </>
  )}
        <div class="list-disc mt-1 ml-10 flex items-center ">
        <span onClick={() => toggleSection('languages')} style={{ cursor: 'pointer' }}>
        {isLanguagesOpen ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
        </span>
        <span onClick={() => toggleSection('languages')} style={{ cursor: 'pointer' }}> &nbsp; Languages</span> 
        </div>
      {isLanguagesOpen && (
            <ul class="circle mt-2 ml-20 space-y-0">
            <li>   <input
                      type="checkbox"
                      disabled="true"
                    /> Pass the DELF A1 Exam - French</li>
            <li>   <input
                      type="checkbox"
                      disabled="true"
                    /> Pass the DELF A2 Exam - French</li>       
            </ul>
      )}
        </>
      )}
      <hr className="w-full my-2 border-gray-400" />
      <div className="flex items-center">
        <span className="mr-2" onClick={() => toggleSection('retiredQuests')} style={{ cursor: 'pointer' }}> 
        {isRetiredQuestsOpen ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
        </span>
        <span className="mr-2" onClick={() => toggleSection('retiredQuests')} style={{ cursor: 'pointer' }}>📜 Abandoned Quests</span>
      </div>
      {isRetiredQuestsOpen && (
        <>
           <ul class="list-disc mt-1 ml-10 space-y-0">
            <li> :) </li>
          </ul>
        </>
      )}
   <hr className="w-full my-2 border-gray-400" />
<div className="flex items-center">
        <span className="mr-2" onClick={() => toggleSection('Bookshelf')} style={{ cursor: 'pointer' }}>
        {isBookShelfOpen ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
        </span>
        <span className="mr-2" onClick={() => toggleSection('Bookshelf')} style={{ cursor: 'pointer' }}>📚 Bookshelf</span>
      </div>
      {isBookShelfOpen && (
        <>
           <ul class="list-disc mt-4 ml-10 space-y-0">
          <li><input type="checkbox" defaultChecked={true} disabled="true" />  "The Kite Runner" by Khaled Hosseini</li>
          <li><input type="checkbox" defaultChecked={true} disabled="true" />  "The Black Diggers" by Tom Wright</li>
          <li><input type="checkbox" defaultChecked={true} disabled="true" />  "The Diary of a Wimpy Kid" series by Jeff Kinney (till 2015)</li>
          <li><input type="checkbox" defaultChecked={true} disabled="true" />  "Animal Farm" by George Orwell</li>
          <li><input type="checkbox" defaultChecked={true} disabled="true" />  "3 Mistakes of my Life" by Chetan Bhagat</li>
          <li><input type="checkbox" defaultChecked={true} disabled="true" />  "David Copperfield" by Charles Dickens</li>
          <li><input type="checkbox" defaultChecked={true} disabled="true" />  "Oliver Twist" by Charles Dickens</li>
          <li><input type="checkbox" defaultChecked={true} disabled="true" />  "The Alchemist" by Paulo Coelho</li>
          <li><input type="checkbox" defaultChecked={false} disabled="true" />  "Shantaram" by Gregory David Roberts</li>
          <li><input type="checkbox" defaultChecked={false} disabled="true" />  "The Diary of a Young Girl" by Anne Frank</li>
          </ul>
        </>
      )}

      <hr className="w-full my-2 border-gray-400" />
      <div className="flex items-center">
        <span className="mr-2" onClick={() => toggleSection('worldTravelLog')} style={{ cursor: 'pointer' }}>
        {isWorldTravelLogOpen ? <img src={arrowDown} alt="Down Arrow"  className="responsive-icon" /> : <img src={arrowRight} alt="Right Arrow"  className="responsive-icon" />} 
        </span>
        <span className="mr-2" onClick={() => toggleSection('worldTravelLog')} style={{ cursor: 'pointer' }}> ✈️ World Travel Log</span>
      </div>
      {isWorldTravelLogOpen && (
        <>
            <br />
            <a className='mb-20 socials' href="/travel"> Travel Map</a> <br />
            Continents Visited: 2/7<br />
            Countries Lived In: India, Australia<br />
            <p>Countries Visited: 5/195 (2.56%)</p>
            <ul class="list-disc mt-4 ml-10 space-y-0">
            <li>2016: Hong Kong, India</li>
            <li>2019: Singapore, India</li>
            <li>2020:  </li>
            <li>2021:  </li>
            <li>2022: India, Indonesia</li>
            <li>2023: Indonesia, Thailand, Singapore</li>
            <li>2024: </li>
          </ul>
        </>
      )}
     
      <hr className="w-full my-2 border-gray-400" />
      <a className='mb-20 socials' href="https://twitter.com/Dabsesh0943"> Twitter</a> |  <a className='mb-20 socials' href="https://www.linkedin.com/in/dipeshgoyal/"> LinkedIn </a> | <a className='mb-20 socials' href="/blogs"> Blogs </a>
      <br /><br /><br />
    </div>
    <Footer />
    </div>
  );
};

export default Home;
