// BlogPost.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import API_BASE_URL from '../config';
import Footer from '../components/footer';
import Breadcrumbs from '../components/Breadcrumbs';

const BlogPost = () => {
  const { id } = useParams();
  const [blogPost, setBlogPost] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/blog/${id}`);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setBlogPost(data);
        setError(null);
      } catch (error) {
        console.error(error);
        setBlogPost({});
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // Check for loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Check for errors
  if (error) {
    return (
      <div>
        <h2 className="text-2xl font-semibold">Blog Post</h2>
        <p className="text-red-500">Error: {error.message}</p>
      </div>
    );
  }

  // Check if the blog post is found
  if (!blogPost || !blogPost.title) {
    return (
      <div>
        <h2 className="text-2xl font-semibold">Blog Post</h2>
        <p className="text-red-500">Error: Invalid blog post URL</p>
      </div>
    );
  }

  // Display the title and content of the blog post
  return (
    <div className="flex flex-col min-h-screen">
      <div className="nav">
      <Breadcrumbs paths={[
        { label: 'Home', url: '/' },
        { label: 'Blogs', url: '/blog' },
        { label: blogPost.title, url: `/blog/${id}` },
      ]} />
      
      <h1 className="blog-title text-4xl font-medium text-black mt-6 mb-4">{`${blogPost.title}`}</h1>  
      </div>
      <div className="content flex-1" dangerouslySetInnerHTML={{ __html: blogPost.content }} />
      <hr id="hr" className="mt-10 mb-2" />
      <div className="date">
        <strong>Date: {blogPost.date}</strong>
        </div>
      <Footer />
    </div>
  );
};

export default BlogPost;
