// Travel.js
import React, { useState, useEffect } from 'react';
import Footer from '../components/footer';
import Breadcrumbs from '../components/Breadcrumbs';
import Map from '../components/map';
import Legend from '../components/legend'; // Import the Legend component
import API_BASE_URL from '../config';

const Travel = () => {
  const breadcrumbs = [
    { label: 'Home', url: '/' },
    { label: 'Travel', url: '/travel' },
  ];

  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch data from the API when the component mounts
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/travel`); // Adjust the API endpoint accordingly
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setCountries(data);
      } catch (error) {
        console.error('Error fetching countries:', error);
        // Handle error, e.g., set an error state
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Check if countries data is available
  if (loading) {
    return (
      <div className="flex flex-col min-h-screen">
        <div className="nav">
          <Breadcrumbs paths={breadcrumbs} />
        </div>
        <div className="p mt-8 flex-1">
          <h2 className="text-2xl font-semibold mb-4">Countries</h2>
          <div>Loading...</div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!countries || countries.length === 0) {
    console.log('No countries data available');
    return (
      <div className="flex flex-col min-h-screen">
        <div className="nav">
          <Breadcrumbs paths={breadcrumbs} />
        </div>
        <div className="p mt-8 flex-1">
          <h2 className="text-2xl font-semibold mb-4">Countries</h2>
          <div>No countries data available</div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="nav">
        <Breadcrumbs paths={breadcrumbs} />
      </div>
      <h2 className="p text-2xl font-semibold mb-6">Countries</h2>
    
      {/* Render the Map component within a container */}
      <div className="map-container">
    
        <Map countries={countries} />
        <Legend /> 
   
      </div>
    
      <Footer />
    </div>
  );
};

export default Travel;
