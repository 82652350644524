// Legend.js
import React from 'react';

const Legend = () => {
  return (
    <div className="legend">
      <div>
        <span className="legend-icon" style={{ backgroundColor: 'red' }}></span>
        <span>To Travel</span>
      </div>
      <div>
        <span className="legend-icon" style={{ backgroundColor: 'green' }}></span>
        <span>Traveled</span>
      </div>
      <div>
        <span className="legend-icon" style={{ backgroundColor: 'black' }}></span>
        <span>Planning next</span>
      </div>
    </div>
  );
};

export default Legend;
