import React from 'react';

const Footer = () => (
  <footer className="bg-white-100 text-center py-4 mt-auto">
    <p className="font-bold text-gray-800">
      &copy; Dipesh 2023
    </p>
  </footer>
);

export default Footer;
